.dot {
    height: 25px;
    width: 25px;
    
    border-radius: 50%;
    display: inline-block;
  }

.connected{
    background-color: rgb(68, 159, 15);
}

.not-connected{
    background-color: #bbb;
}