/* Left Filter Box */
.left_filter {
    margin: -10px;
}

.select_title {
    font-weight: bold;
    font-size: 21px;
}

.fault-alert-box {
    background-color: orange;
    border-radius: 8px;
}