/* Common */
.body {
    font-size: 20px;
    min-width: 1300px;
}

.align-right {
    text-align: right;
}

.margin-top-10 {
    margin-top: 10px;
}

.margin_left_-15 {
    margin-left: -15px;
}

.justify-content-md-center {
    justify-content: center;
}

.plotCSS {
    width: 100%;
    min-height: 700px;
}

.plot_button {
    border-radius: 20px !important;
    font-size: 22px !important;
    padding: 5px 15px !important;
    margin-top: 40px;
}

.form-control {
    font-size: 1.2rem !important;
    min-width: 80px;
}

.x_button {
    padding: .375rem .75rem;
}

/* Common CSS for Table */
.react-bootstrap-table table {
    line-height: 1.2;
    text-align: center;
}

.react-bootstrap-table {
    min-width: fit-content;
}

.table-bordered thead td,
.table-bordered thead th {
    background-color: rgb(235, 231, 231);
}

.table td,
.table th {
    padding: 6px !important;
}

.table th {
    font-size: 20px;
    min-width: 150px;
}

/*for arrows in table for sorting*/
.order-4 {
    font-size: 15px;
}


.np_table tr:nth-child(5) {
    background-color: rgb(235, 231, 231);
    height: 35px;
}

.react-datepicker__day--outside-month {
    visibility: hidden;
}


/* Top Navigation */
.logoutbutton-ctn {
    margin: 27px -55px 0px -25px;
    text-align: center;
}

.timezone-ctn {
    margin: 24px 0px;
    text-align: left;
    line-height: 1.5rem;
    max-width: 400px !important;
}

/* Left Filter Box */
.left_filter {
    margin: -10px;
}

.select_title {
    font-weight: bold;
    font-size: 21px;
}

.np_table {
    text-align: center;
    font-size: 18px;

}

/*  WaveForm Analysis Tab  */

.waveform-sec-ctn .form-control {
    width: 100px;
    font-size: 22px;
}

.waveform-date input {
    width: 180px;
}

.waveformAlert {
    margin-top: 30px;
}


/*  Harmonic Analysis Tab  */

.plotfft_table1 table tr th:nth-child(1) {
    width: 30%;
}

.plotfft_table1 table tr th {
    width: 12%;
}

.harmonic-date input {
    width: 180px;
}

.hatreport_tableCSS {
    height: 425px;
    overflow: scroll;
    padding-bottom: 2px;
    padding-top: 2px;
}

.hatreport_tableCSS .table-bordered thead td,
.hatreport_tableCSS .table-bordered thead th {
    position: sticky;
    top: -3px;
}

.HatReportPlotCSS {
    width: 100%;
    height: 45vw;
    min-height: 700px;
}

.harmonicAlert {
    margin-top: 30px;
}

/* Trending Analysis Tab */

.min_table_row {
    margin-left: 0px !important;
    margin-right: 0px !important;
}

.min_table_CSS {
    padding-bottom: 2px;
    padding-top: 20px;
    overflow: auto;
}

.minute-dateRange-input input {
    width: 180px;
}

.plotCSS_1sec {
    width: 100%;
    min-height: 900px;
}

.oneSec-date-input input {
    width: 250px !important
}

.trending-sec-ctn .form-control {
    width: 200px;
    font-size: 22px;
    color: black
}

.trendingAlert {
    margin-top: 30px;
}

/* Event Analysis Tab */
.event-data input {
    width: 180px;
}

.eventSortTable_row {
    margin-left: 0px !important;
    margin-right: 0px !important;
}

.eventSorting_tableCSS {
    height: 400px;
    padding-bottom: 2px;
    padding-top: 2px;
    overflow: auto;
}

.eventSorting_tableCSS .react-bootstrap-table table {
    table-layout: inherit !important;
}

.eventSorting_tableCSS .table-bordered thead td,
.eventSorting_tableCSS .table-bordered thead th {
    position: sticky;
    top: 126px;
}

.eventSorting_tableCSS .exportCSV_ctn {
    position: sticky !important;
    top: -2px;
    background-color: white;
    margin-top: -3px;
    width: auto;

}

.eventSorting_tableCSS .eventSearchBox_ctn {
    position: sticky !important;
    top: 56px;
    background-color: white;
    width: auto;
}

.FreqCalPlotCSS {
    width: 100%;
    min-height: 900px;
}

.eventsAlert {
    margin-top: 30px;
}

.event_exportBtn {
    margin: 10px 0px 10px 0px;
}

.eventSearchBox {
    margin: 10px 300px 10px 0px;
}

.eventSearch_clearBtn {
    margin: 10px 0px 10px 20px;
}

.correlatingSearchBox {
    margin: 10px 300px 10px 10px;
}

.correlatingSearch_clearBtn {
    margin: 10px 0px 10px 20px;
}

.correlating_exportBtn {
    margin: 10px 0px 10px 10px;
}

.supportEmail,
.supportEmail:hover,
.supportEmail:active,
.supportEmail:visited {
    font-size: 16px !important;
    text-decoration: none;
}

.display-inline {
    display: inline;
}


.export-csv-button {
    margin-left: 30px;
}

.placeholder-box {
    height: 50px;
    width: 100px;
    background-color: #ffffff;
    border: 1px solid #ffffff;
    border-radius: 5px;
}

.alert {
    white-space: break-spaces;
}