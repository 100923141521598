.voltageLabel {
    font-size: 15px;
    padding-top: 9px !important;
    padding-left: 5.6% !important;
}

.l-lDropdown {
    font-size: 14px;
    padding-top: 3px !important;
}

.dropdown-alert {
    /* margin-bottom: -7rem; */
    position: absolute;
    z-index: 1;
}

@media only screen and (max-width: 1500px) {

    .voltageLabel {
        padding-left: 5% !important;
    }
}