.plot-loading-bars {
  width: 100%;
  min-height: 720px;
  position: absolute;
  z-index: 2;
  padding-left: 50%;
  padding-top: 330px;
  height: 100%;
}

@supports(backdrop-filter: blur(5px)) {
  .plot-loading-bars {
    backdrop-filter: blur(10px);
  }
}

/* slightly transparent fallback for Firefox (not supporting backdrop-filter) */
@supports not ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
  .plot-loading-bars {
    background-color: rgba(255, 255, 255, .8);
    filter: blur(3px);
  }
}