
.alertsTable {
	font-size: 14px;
}

.first-col-width th:nth-child(1){
	width: 20%;
}

.facilitiesMap .card-header{
    text-align: center;
}

.facilitiesMap .row{
    margin: 0 0 0 0;
}

.facilitiesMap .col{
    display: flex;
    justify-content: center;
    height: 520px;
    overflow: hidden;
    padding: 0 0 0 0;
}

.facilitiesMap .js-plotly-plot{
    position: relative;
    top: -200px;
}